
















import {Component, Prop, Vue} from 'vue-property-decorator';
import LeftMenu from '@/components/Layout/LeftMenu/LeftMenu.vue';
import Loader from '@/components/Layout/Loader/Loader.vue';
import '@/assets/scss/styles.scss';
import {Meta} from '@/decorators';

@Component({
  // metaInfo() {
  //   return {
  //     title: 'Website',
  //     titleTemplate: '%s | Jozef Vavrinec',
  //     // base: { target: '_blank', href: process.env.BASE_URL },
  //     htmlAttrs: {
  //       lang: 'en',
  //       amp: undefined // "amp" has no value
  //     }
  //   };
  // },
  components: {
    LeftMenu,
    Loader,
  },
})
export default class App extends Vue {

  constructor() {
    super();
  }

  @Meta
  public metaInfo() {
    const settings = {
      title: 'Website',
      titleTemplate: '%s | Jozef Vavrinec',
      base: {target: '_blank', href: process.env.BASE_URL},
      htmlAttrs: {
        lang: 'en',
      },
      meta: [{}]
    };
    settings.meta = [
      {
        vmid: 'description',
        name: 'description',
        content: 'Jozef Vavrinec - Full-Stack developer - Personal web page'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'Jozef,Vavrinec,Jozef Vavrinec,full-stack,fullstack,developer,personal,' +
          'php,html,css,scss,js,typescript,vuejs,vue,angular,yii,cakephp'
      },
      {
        property: 'og:title',
        content: settings.title,
        // template: (chunk) => `${chunk} | Jozef Vavrinec`,
        template: settings.titleTemplate,
        vmid: 'og:title'
      }
    ];

    return settings;
  }
}
