import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(fas);

// import 'vuetify/dist/vuetify.min.css';
// import Vuetify from 'vuetify';

// Vue.use(Vuetify);

Vue.component('font-awesome-icon', FontAwesomeIcon);


import Meta from 'vue-meta';

Vue.use(Meta);

// Plugins
import './../node_modules/jquery/dist/jquery.min.js';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './../node_modules/bootstrap/dist/js/bootstrap.min.js';


Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  const element = document.getElementById('page-view');
  if (element) {
    element.classList.add('hide');
    element.classList.remove('render-complete');
    element.style['left'] = '100vw';
  }
  setTimeout(() => {
    if (element) {
      element.style['transform'] = 'translateX(-200vw)';
      element.style['left'] = '-100vw';
    }
    setTimeout(() => {
      next();
    }, 500);
  }, 500);
});

router.afterEach((to, from) => {
  const element = document.getElementById('page-view');
  if (element) {
    // element.classList.remove('hide');
    element.style['transform'] = 'translateX(0)';
    element.style['left'] = '0';
    element.classList.remove('hide');
    element.classList.add('render-complete');
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
