import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})

export default class LeftMenu extends Vue {
  public leftMenuBig: boolean = false;
  public leftMenuItems: object = [];
  public leftMenuItemsDEV = [
    {
      name: 'Home',
      icon: 'home',
      tooltip: 'Index',
      link: '/',
    },
    {
      name: 'About',
      icon: 'info',
      tooltip: 'About me',
      link: '/about',
    },
    {
      name: 'Projects',
      icon: 'laptop-code',
      tooltip: 'My projects',
      link: '/projects',
    },
    {
      name: 'Price offer',
      icon: 'handshake',
      tooltip: 'Order my services',
      link: '/price',
    },
    {
      name: 'Contact',
      icon: 'at',
      tooltip: 'Contact me',
      link: '/contact',
    },
    {
      name: 'Error',
      icon: 'exclamation-triangle',
      tooltip: 'Error 404',
      link: '/foo',
    },
  ];
  public leftMenuItemsPROD = [
    {
      name: 'Home',
      icon: 'home',
      tooltip: 'Index',
      link: '/',
    }
  ];

  constructor() {
    super();
    if (process.env.NODE_ENV === 'production') {
      this.leftMenuItems = this.leftMenuItemsPROD;
    } else {
      this.leftMenuItems = this.leftMenuItemsDEV;
    }
  }

  public collapseLeftMenu() {
    this.leftMenuBig = !this.leftMenuBig;
  }
}
