import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home/Home.vue';
import NotFound from './views/NotFound.vue';
import Projects from '@/views/Projects/Projects';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    },
    {
      path: '/projects',
      name: 'Projects',
      component: Projects
    },
    {
      path: '/404',
      name: '404 - Not Found',
      component: NotFound,
    },
    {path: '*', redirect: '/404'},
  ],
});
