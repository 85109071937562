import {Component, Vue} from 'vue-property-decorator';
import {VueTypedJs} from 'vue-typed-js';
import {Meta} from '@/decorators';
import '../../../node_modules/particles.js/particles.js';

@Component({
  // metaInfo() {
  //   return {
  //     title: Home.$testModel
  //   };
  // },
  components: {
    VueTypedJs
  }
})

export default class Home extends Vue {
  // title: '';
  public particlesJS: any;
  public iUseWords: any[] = [];

  constructor() {
    super();
    // particlesJS.load('particles-js', 'particles.json', null);
    // this.particlesJS = window['particlesJS'];
    // window.particlesJS()
    // this.initParticles();
    this.iUseWords = [
      'Yii, CakePHP, Symfony',
      'VueJS, Angular',
      'Bootstrap, jQuery, SCSS, TypeScript',
      'PHP, SQL, JavaScript, Html5 + CSS3',
      'Linux servers, Bash, API ...'
    ];
  }

  @Meta
  public metaInfo() {
    return {
      title: 'Home',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Jozef Vavrinec - Full-Stack developer - Personal web page - Home'
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content:
            'Jozef,Vavrinec,Jozef Vavrinec,full-stack,fullstack,developer,personal,' +
            'php,html,css,scss,js,typescript,vuejs,vue,angular,yii,cakephp,home'
        }
      ]
    };
  }

  public mounted() {
    this.initParticles();
  }

  public initParticles() {
    window['particlesJS']('particles-js', {
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 700
          }
        },
        color: {
          value: '#ffffff'
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 0,
            color: '#000000'
          },
          polygon: {
            nb_sides: 5
          }
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#ffffff',
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 6,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: true,
            mode: 'grab'
          },
          onclick: {
            enable: true,
            mode: 'push'
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3
          },
          repulse: {
            distance: 200,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true
    });
  }
}
