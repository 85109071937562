import { render, staticRenderFns } from "./Projects.vue?vue&type=template&id=4542c3fc&scoped=true&"
import script from "./Projects.ts?vue&type=script&lang=ts&"
export * from "./Projects.ts?vue&type=script&lang=ts&"
import style0 from "./Projects.scss?vue&type=style&index=0&id=4542c3fc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4542c3fc",
  null
  
)

component.options.__file = "Projects.vue"
export default component.exports