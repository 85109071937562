import {Component, Prop, Vue} from 'vue-property-decorator';
import SectionGoBack from '@/components/SectionGoBack/SectionGoBack';
import MySection from '@/components/MySection/MySection';
import {Meta} from '@/decorators';

@Component({
  components: {SectionGoBack, MySection},
})

export default class Projects extends Vue {

  @Meta
  public metaInfo() {
    return {
      title: 'Projects',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Jozef Vavrinec - Full-Stack developer - Personal web page - Projects'
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content:
            'Jozef,Vavrinec,Jozef Vavrinec,full-stack,fullstack,developer,personal,' +
            'php,html,css,scss,js,typescript,vuejs,vue,angular,yii,cakephp,projects'
        }
      ]
    };
  }
}
