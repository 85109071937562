import {Component, Prop, Vue} from 'vue-property-decorator';
import SectionGoBack from '@/components/SectionGoBack/SectionGoBack';

@Component({
  components: {SectionGoBack},
})

export default class MySection extends Vue {
  @Prop() private title!: string;
  @Prop() private goBack!: boolean;
  @Prop() private name!: boolean;
}
