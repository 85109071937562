












import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Home extends Vue {
  public name: string = '404 - NotFound';

  constructor() {
    super();
  }
}
