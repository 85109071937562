import { render, staticRenderFns } from "./MySection.vue?vue&type=template&id=c29e1b52&scoped=true&"
import script from "./MySection.ts?vue&type=script&lang=ts&"
export * from "./MySection.ts?vue&type=script&lang=ts&"
import style0 from "./MySection.scss?vue&type=style&index=0&id=c29e1b52&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c29e1b52",
  null
  
)

component.options.__file = "MySection.vue"
export default component.exports